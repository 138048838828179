@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Bold'),
    url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Italic'),
    url(./assets/fonts/OpenSans-Italic.ttf) format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'),
    url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-SemiBold'),
    url(./assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
