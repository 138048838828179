$primary: #0c465e;
$secondary: #5792a0;
$dark: #343a40;
$light: #ebeceb;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
