html,
body,
#root {
  height: 100%;
  overflow: auto;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'OpenSans-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
}

.nav-tabs {
  border: none;
  box-shadow: 0 2px 14px -2px rgb(199, 199, 199);
  padding-left: 27px;
}

.nav-link {
  border: none !important;
  border-radius: 0px !important;
  padding: 13px;
}

.nav-link.active {
  background-color: transparent;
  background-color: #5792a0 !important;
  color: #fff !important;
}

.table > :not(:first-child) {
  border-top: none;
}
